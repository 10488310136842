import React, { useRef, useState } from 'react'
import { ActionMenu, Avatar, Button, Item, Separator } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { RequisitionInterface } from '@src/interfaces/requisitions'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { ResolveIconType } from '@revolut/ui-kit/types/dist/components/Icon/utils'
import { navigateTo } from '@src/actions/RouterActions'

type HeaderMenuProps = {
  jobPostingId?: number
  label: string
  useIcon?: ResolveIconType
  onConnectJobPosting: () => void
}

export const HeaderMenu = ({
  jobPostingId,
  label,
  useIcon,
  onConnectJobPosting,
}: HeaderMenuProps) => {
  const { values } = useLapeContext<RequisitionInterface>()
  const anchorRef = useRef(null)
  const [openDropdown, setOpenDropdown] = useState(false)
  return (
    <>
      <Button
        size="xs"
        variant="text"
        useIcon={useIcon}
        ref={anchorRef}
        onClick={() => {
          setOpenDropdown(!openDropdown)
        }}
        data-testid="job_posting_actions"
      >
        {label}
      </Button>
      <ActionMenu
        open={openDropdown}
        anchorRef={anchorRef}
        onClose={() => {
          setOpenDropdown(false)
        }}
      >
        <ActionMenu.Group>
          <ActionMenu.Item
            onClick={() => {
              navigateTo(pathToUrl(ROUTES.FORMS.JOB_POSTING_FLOW.DETAILS), {
                requisitionId: String(values.id),
                specialisationId: String(values.specialisation.id),
              })
            }}
            useIcon="Plus"
          >
            Create new job posting
          </ActionMenu.Item>
          <ActionMenu.Item
            use="button"
            useIcon="Plug"
            onClick={() => {
              setOpenDropdown(false)
              onConnectJobPosting()
            }}
          >
            {jobPostingId
              ? 'Connect to other job posting'
              : 'Connect to existing job posting'}
          </ActionMenu.Item>
          <Separator />
          {!!jobPostingId && (
            <ActionMenu.Item
              target="_blank"
              to={pathToUrl(ROUTES.FORMS.JOB_POSTING.PREVIEW, {
                specId: values.specialisation.id,
                id: jobPostingId,
              })}
              use={InternalLink}
              useIcon="16/LinkExternal"
            >
              <ActionMenu.Title>View job posting</ActionMenu.Title>
              <ActionMenu.Description>Open in a new tab</ActionMenu.Description>
            </ActionMenu.Item>
          )}
        </ActionMenu.Group>
      </ActionMenu>
    </>
  )
}

type HeaderItemProps = {
  title?: string
  side: React.ReactNode
}

export const HeaderItem = ({ title, side }: HeaderItemProps) => {
  return (
    <Item>
      <Item.Avatar>
        <Avatar useIcon="DataLimit" />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{title ?? 'Job Posting'}</Item.Title>
        {!!title && <Item.Description>Job posting overview</Item.Description>}
      </Item.Content>
      <Item.Side>{side}</Item.Side>
    </Item>
  )
}

import React, { useState } from 'react'
import { ApplicationForm as ApplicationFormBody } from '@src/pages/Forms/JobPosting/ApplicationForm/ApplicationForm'
import { PageActions } from '@src/components/Page/PageActions'
import { PageBody } from '@src/components/Page/PageBody'
import { ActionButton, Avatar, Button, Item, Portal } from '@revolut/ui-kit'
import { useLocation, useParams } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { navigateTo } from '@src/actions/RouterActions'
import {
  JobPostingFlowParams,
  JobPostingLocationState,
} from '@src/pages/Forms/JobPostingFlow/types'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { getBackUrl } from '@src/pages/Forms/JobPostingFlow/utils'
import JobPostingPopup from '@src/pages/Forms/JobPostingFlow/components/JobPostingPopup'
import Form from '@src/features/Form/Form'
import { JobDescriptionFormRequest } from '@src/api/jobPosting'
import { JobPostingContentHeader } from '@src/pages/Forms/JobPostingFlow/components/JobPostingContent'

type ApplicationFormPreviewProps = {
  jobPosting: JobPostingInterface
}

const ApplicationFormPreview = ({ jobPosting }: ApplicationFormPreviewProps) => {
  return (
    <Form
      api={JobDescriptionFormRequest}
      validator={{}}
      disableLocalStorageCaching
      forceParams={{ id: String(jobPosting.id) }}
    >
      <JobPostingContentHeader jobPosting={jobPosting} />
      <ApplicationFormBody disableEditing />
    </Form>
  )
}

type ReuseApplicationFormProps = {
  specialisationId?: number | string
}

const ReuseApplicationForm = ({ specialisationId }: ReuseApplicationFormProps) => {
  const { values } = useLapeContext<JobPostingInterface>()
  const [openJobPostings, setOpenJobPostings] = useState(false)
  return (
    <>
      {openJobPostings && (
        <Portal>
          <JobPostingPopup
            confirmLabel="Reuse form"
            renderJobPosting={ApplicationFormPreview}
            specialisationId={specialisationId}
            titleLabel="Application forms"
            onClose={() => {
              setOpenJobPostings(false)
            }}
            onConfirm={jobPosting => {
              if (jobPosting.application_form_sections) {
                values.application_form_sections = jobPosting.application_form_sections
              }
            }}
          />
        </Portal>
      )}
      <Item
        use="label"
        onClick={() => {
          setOpenJobPostings(true)
        }}
      >
        <Item.Avatar>
          <Avatar useIcon="WizardHat" />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>Reuse existing application forms </Item.Title>
          <Item.Description>
            Speed up by reusing content from previously posted applications
          </Item.Description>
        </Item.Content>
        <Item.Side>
          <ActionButton useIcon="Search">Browse applications</ActionButton>
        </Item.Side>
      </Item>
    </>
  )
}

type ApplicationFormProps = {
  onAfterSubmit: () => void
}

const ApplicationForm = ({ onAfterSubmit }: ApplicationFormProps) => {
  const { values } = useLapeContext<JobPostingInterface>()
  const params = useParams<JobPostingFlowParams>()
  const { state: locationState } = useLocation<JobPostingLocationState>()
  return (
    <>
      <PageBody>
        <ReuseApplicationForm specialisationId={values.specialisation?.id} />
        <ApplicationFormBody useSubheader />
      </PageBody>
      <PageActions>
        <Button
          use={InternalLink}
          onClick={() => {
            navigateTo(
              pathToUrl(ROUTES.FORMS.JOB_POSTING_FLOW.DESCRIPTION, params),
              locationState,
            )
          }}
          variant="secondary"
          elevated
        >
          Back
        </Button>
        <NewSaveButtonWithPopup<JobPostingInterface>
          id={String(values.id)}
          useValidator
          hideWhenNoChanges={false}
          onAfterSubmit={() => {
            onAfterSubmit()
            navigateTo(getBackUrl(params, locationState))
          }}
          successText="Job posting saved successfully"
        >
          Next
        </NewSaveButtonWithPopup>
      </PageActions>
    </>
  )
}

export default ApplicationForm

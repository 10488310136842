import React from 'react'
import { MoreBar } from '@revolut/ui-kit'
import { useParams } from 'react-router-dom'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { ArchiveButton, EditButton } from '@src/features/SettingsButtons'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import { EntityPermissions } from '@src/store/auth/types'
import { PublishAction } from '@src/pages/Forms/JobPosting/Preview/PublishStatus'
import { useGetJobPostingSettings } from '@src/api/settings'
import { ApproveButton, RejectButton } from '@src/features/SettingsButtons'
import { handleError } from '@src/api'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import { useGetJobDescriptionApprovals } from '@src/api/jobPosting'
import { navigateTo } from '@src/actions/RouterActions'

type JobPostingActionsProps = {
  openRequisitionCount: number
  onApproving: (isApproving: boolean) => void
}

const JobPostingActions = ({
  openRequisitionCount,
  onApproving,
}: JobPostingActionsProps) => {
  const { values } = useLapeContext<JobPostingInterface>()
  const params = useParams<{ id: string; specId: string }>()
  const { data: jobPostingSettings } = useGetJobPostingSettings()
  const enableApprovals = jobPostingSettings?.enable_approvals
  const { refetch: refetchApproval } = useGetJobDescriptionApprovals(
    enableApprovals ? values?.id : undefined,
  )
  const canChange = !!values.field_options?.actions?.includes?.(EntityPermissions.Change)
  const canApprove =
    values?.approval_status === ApprovalStatuses.Pending &&
    values?.field_options?.actions?.includes(EntityPermissions.Approve)
  const onBeforeApproval = () => onApproving(true)
  const onAfterApproval = () => {
    onApproving(false)
    refetchApproval()
  }
  return (
    <MoreBar>
      <EditButton
        onClick={() => {
          navigateTo(
            pathToUrl(ROUTES.FORMS.JOB_POSTING_FLOW.DETAILS, {
              id: params.id,
            }),
            {
              specialisationId: params.specId,
            },
          )
        }}
        isVisible={canChange}
      />
      {canApprove && (
        <>
          <ApproveButton
            isVisible={enableApprovals}
            onBeforeSubmit={onBeforeApproval}
            onAfterSubmit={onAfterApproval}
            onSubmitFailed={handleError}
            statusFieldName="approval_status"
          />
          <RejectButton
            isVisible={enableApprovals}
            onBeforeSubmit={onBeforeApproval}
            onAfterSubmit={onAfterApproval}
            onSubmitFailed={handleError}
            statusFieldName="approval_status"
            dialog={{
              title: 'Reason for rejection',
              placeholder: 'a couple of words',
              fieldName: 'rejection_comment',
            }}
          />
        </>
      )}
      {!!openRequisitionCount && <PublishAction />}
      <ArchiveButton
        entityPermissions={EntityPermissions.Change}
        isVisible={!!values?.id}
        statusFieldName="approval_status"
      />
    </MoreBar>
  )
}

export default JobPostingActions

import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  JobPostingFlowParams,
  JobPostingLocationState,
} from '@src/pages/Forms/JobPostingFlow/types'

export const getBackUrl = (
  params: JobPostingFlowParams,
  locationState: JobPostingLocationState,
) => {
  if (params.id) {
    return pathToUrl(ROUTES.FORMS.JOB_POSTING.PREVIEW, {
      id: params.id,
      specId: locationState?.specialisationId,
    })
  }
  if (locationState?.requisitionId) {
    return pathToUrl(ROUTES.FORMS.REQUISITION.ROLE, {
      id: locationState.requisitionId,
    })
  }
  if (locationState?.specialisationId) {
    return pathToUrl(ROUTES.FORMS.SPECIALISATIONS.POSTINGS, {
      id: locationState.specialisationId,
    })
  }
  return pathToUrl(ROUTES.RECRUITMENT.JOB_POSTINGS)
}

import React from 'react'
import { Button, Checkbox, Subheader, Text, VStack, Widget } from '@revolut/ui-kit'
import { createRequisitionTitleColumn } from '@src/constants/columns/requisition'
import {
  JobPostingFlowParams,
  JobPostingLocationState,
} from '@src/pages/Forms/JobPostingFlow/types'
import { JobPostingInterface, LocationSource } from '@src/interfaces/jobPosting'
import { lineManagerColumn, recruiterNameColumn } from '@src/constants/columns/employee'
import {
  LocationInterface,
  RequisitionInterface,
  initialRequisitionStatusFilter,
} from '@src/interfaces/requisitions'
import { PageBody } from '@src/components/Page/PageBody'
import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import { seniorityNameRequisitionsColumn } from '@src/constants/columns/seniority'
import { TableNames } from '@src/constants/table'
import { teamNameColumn } from '@src/constants/columns/team'
import { useGetHiringEnabledLocations, useGetRequisition } from '@src/api/requisitions'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { useLocation, useParams } from 'react-router-dom'
import { useRequisitionTable } from '@src/features/CommonRequisitionTable/CommonRequisitionTable'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import LapeNewMultiSelect from '@components/Inputs/LapeFields/LapeNewMultiSelect'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import NewMultiSelect from '@src/components/Inputs/NewMultiSelect/NewMultiSelect'
import { PageActions } from '@src/components/Page/PageActions'
import { getBackUrl } from '@src/pages/Forms/JobPostingFlow/utils'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { navigateTo } from '@src/actions/RouterActions'
import PostingCompensationBand from '@src/components/LocationCompensationBand/PostingCompensationBand'
import { useGetJobPostingSettings } from '@src/api/settings'
import { useGetPostingCompensationBands } from '@src/api/benchmarks'
import ManualCompensationBandForm from '@src/pages/Forms/JobPosting/Components/ManualCompensationBandForm'

const requisitionRow = (): RowInterface<RequisitionInterface> => ({
  cells: [
    {
      ...createRequisitionTitleColumn(false),
      width: 200,
    },
    {
      ...seniorityNameRequisitionsColumn,
      width: 100,
    },
    {
      ...teamNameColumn,
      width: 130,
    },
    {
      ...lineManagerColumn,
      width: 130,
    },
    {
      ...recruiterNameColumn,
      width: 130,
    },
  ],
})

type DetailsRequisitionTableProps = {
  requisitions: RequisitionInterface[]
}

const DetailsRequisitionTable = ({ requisitions }: DetailsRequisitionTableProps) => {
  return (
    <AdjustableTable<RequisitionInterface>
      name={TableNames.CommonRequisition}
      useWindowScroll
      dataType="Requisition"
      row={requisitionRow()}
      data={requisitions}
      count={requisitions.length}
      hideCountAndButtonSection
      noDataMessage="Requisitions will appear here."
    />
  )
}

const JobPostingRequisitionTable = () => {
  const { values } = useLapeContext<JobPostingInterface>()
  const table = useRequisitionTable({
    filterBy: [
      {
        filters: initialRequisitionStatusFilter,
        columnName: 'status',
        nonResettable: true,
      },
      {
        columnName: 'requisition_posting__job_posting',
        filters: [{ id: values.id, name: String(values.name) }],
        nonResettable: true,
      },
    ],
    sortBy: [
      {
        sortBy: 'status',
        direction: SORT_DIRECTION.ASC,
      },
    ],
  })
  return <DetailsRequisitionTable requisitions={table.data} />
}

const RequisitionsTable = () => {
  const { state: locationState } = useLocation<JobPostingLocationState>()
  const { data: requisition } = useGetRequisition(locationState?.requisitionId)
  return <DetailsRequisitionTable requisitions={requisition ? [requisition] : []} />
}

const locationsToLocationOptions = (locations?: LocationInterface[]) => {
  return (locations ?? []).map(location => ({
    label: location.name,
    value: location,
  }))
}

const HiringLocations = () => {
  const { state: locationState } = useLocation<JobPostingLocationState>()
  const { values, errors } = useLapeContext<JobPostingInterface>()
  const { data: enabledLocationsData } = useGetHiringEnabledLocations()
  const locationOptions = locationsToLocationOptions(enabledLocationsData)
  const { data: requisition } = useGetRequisition(locationState?.requisitionId)
  const requisitionLocations = locationsToLocationOptions(requisition?.locations)
  const { data: jobPostingSettings } = useGetJobPostingSettings()
  const enableAutomaticCompensationBands =
    jobPostingSettings?.enable_automatic_compensation_ranges
  const selectedLocationIds = (values?.locations ?? []).map(
    ({ id: locationId }) => locationId,
  )
  const mandatoryCompensationLocations = (
    jobPostingSettings?.mandatory_compensation_locations ?? []
  )
    .map(({ id: locationId }) => locationId)
    .filter(locationId => selectedLocationIds?.includes(locationId))
  const { data: compensationBands } = useGetPostingCompensationBands(
    values.id,
    values.locations
      ?.filter(
        item =>
          item.posting_compensation_enabled ||
          mandatoryCompensationLocations.includes(item.id),
      )
      ?.map(item => item.id),
  )
  return (
    <Widget p="s-16">
      <VStack gap="s-16">
        <Subheader variant="nested">
          <Subheader.Title>Hiring locations</Subheader.Title>
        </Subheader>
        <Checkbox
          checked={values.location_source === LocationSource.requisition}
          onChange={event => {
            const fromRequisitions = event.currentTarget.checked
            if (fromRequisitions) {
              values.location_source = LocationSource.requisition
              values.locations = undefined
            } else {
              values.location_source = LocationSource.manual
            }
          }}
        >
          <Text>Autofill locations from Requisitions</Text>
        </Checkbox>
        {values.location_source === LocationSource.requisition ? (
          <NewMultiSelect
            name="locations"
            placeholder="Locations"
            required
            options={locationOptions}
            value={requisitionLocations}
            disabled
          />
        ) : (
          <>
            <LapeNewMultiSelect<LocationInterface>
              name="locations"
              placeholder="Locations"
              required
              options={locationOptions}
              onAfterChange={locationValues => {
                const entries = Object.entries(values.salary_bands_by_location ?? {})
                const locationKeys = (locationValues ?? []).map(({ label }) => label)
                const keepEntries = entries.filter(([key]) => locationKeys.includes(key))
                if (keepEntries.length) {
                  values.salary_bands_by_location = Object.fromEntries(keepEntries)
                }
              }}
            />
            <PostingCompensationBand bands={compensationBands} />
          </>
        )}
        {!enableAutomaticCompensationBands && !!mandatoryCompensationLocations.length && (
          <>
            {values.location_source !== LocationSource.manual && (
              <PostingCompensationBand bands={compensationBands} />
            )}
            <ManualCompensationBandForm
              locationIds={mandatoryCompensationLocations}
              values={values.salary_bands_by_location}
              errors={errors.salary_bands_by_location}
              onChange={salaryBandsByLocation => {
                values.salary_bands_by_location = values.salary_bands_by_location
                  ? {
                      ...values.salary_bands_by_location,
                      ...salaryBandsByLocation,
                    }
                  : salaryBandsByLocation
              }}
            />
          </>
        )}
      </VStack>
    </Widget>
  )
}

const RequisitionDetails = () => {
  const { values } = useLapeContext<JobPostingInterface>()
  return (
    <VStack>
      <Subheader>
        <Subheader.Title>Requisition details</Subheader.Title>
      </Subheader>
      <VStack gap="s-16">
        {values.id ? <JobPostingRequisitionTable /> : <RequisitionsTable />}
        <HiringLocations />
      </VStack>
    </VStack>
  )
}

type DetailsProps = {
  onAfterSubmit: () => void
}

const Details = ({ onAfterSubmit }: DetailsProps) => {
  const { values } = useLapeContext<JobPostingInterface>()
  const params = useParams<JobPostingFlowParams>()
  const { state: locationState } = useLocation<JobPostingLocationState>()
  const checkRequiredFields = () => {
    const locations =
      values.location_source !== LocationSource.requisition && !values.locations?.length
    return !values.specialisation || !values.recruiter || locations
  }
  return (
    <>
      <PageBody>
        <Subheader>
          <Subheader.Title>Specialisation details</Subheader.Title>
        </Subheader>
        <LapeRadioSelectInput
          name="specialisation"
          label="Specialisation"
          selector={selectorKeys.specialisations}
        />
        <RequisitionDetails />
        <Subheader>
          <Subheader.Title>Recruiter details</Subheader.Title>
        </Subheader>
        <LapeRadioSelectInput
          name="recruiter"
          label="Recruiter"
          selector={selectorKeys.employee}
        />
      </PageBody>
      <PageActions>
        <Button
          onClick={() => {
            navigateTo(getBackUrl(params, locationState), locationState)
          }}
          variant="secondary"
          elevated
        >
          Cancel
        </Button>
        <Button
          disabled={checkRequiredFields()}
          onClick={() => {
            onAfterSubmit()
            navigateTo(
              pathToUrl(ROUTES.FORMS.JOB_POSTING_FLOW.DESCRIPTION, params),
              locationState,
            )
          }}
        >
          Next
        </Button>
      </PageActions>
    </>
  )
}

export default Details

import React, { useState } from 'react'
import { BREAKPOINTS, Box, Flex, Icon } from '@revolut/ui-kit'
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom'

import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { requisitionsRequestsNew } from '@src/api/requisitions'
import { connect } from 'lape'
import { RequisitionInterface } from '@src/interfaces/requisitions'
import * as CommonCandidatesTable from '@src/features/CommonCandidatesTable/CommonCandidatesTable'
import { PermissionTypes } from '@src/store/auth/types'
import {
  RequisitionFormTitle,
  RequisitionFormSubtitle,
} from '@src/pages/Forms/RequisitionForm/RequisitionFormTitles'
import { pathToUrl } from '@src/utils/router'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import InterviewProcess from '@src/pages/Forms/RequisitionForm/InterviewProcess/InterviewProcess'
import Form from '@src/features/Form/Form'
import General from '@src/pages/Forms/RequisitionForm/General/General'
import Preview from '@src/pages/Forms/RequisitionForm/Preview/Preview'
import Changelog from '@src/pages/Forms/RequisitionForm/Changelog/Changelog'
import Hires from '@src/pages/Forms/RequisitionForm/Hires/Hires'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { useGetRequisitionSettings } from '@src/api/settings'
import RequisitionActionBar from '@src/pages/Forms/RequisitionForm/Buttons/RequisitionActionBar'

const Requisition = connect(() => {
  const { data: requisitionSettings } = useGetRequisitionSettings()

  const form = useLapeContext<RequisitionInterface>()
  const { values, initialValues } = form

  const params = useParams<{ id: string }>()

  const isFormRoute = !!useRouteMatch(ROUTES.FORMS.REQUISITION.SETTINGS) || !params.id

  const [isApproving, setIsApproving] = useState(false)

  const defaultBackUrl = ROUTES.RECRUITMENT.REQUISITIONS

  if (isFormRoute) {
    return (
      <PageWrapper>
        <PageHeader
          pb="s-8"
          title={params.id ? 'Edit requisition' : 'Create a new requisition'}
          backUrl={
            params.id ? pathToUrl(ROUTES.FORMS.REQUISITION.ROLE, params) : defaultBackUrl
          }
          subtitle={params.id ? <RequisitionFormSubtitle data={values} /> : null}
        />
        <General />
      </PageWrapper>
    )
  }

  const tabs = [
    {
      title: 'Overview',
      path: ROUTES.FORMS.REQUISITION.ROLE,
      to: pathToUrl(ROUTES.FORMS.REQUISITION.ROLE, params),
      component: Preview,
      icon: <Icon name="InfoOutline" />,
    },
    {
      title: 'Interview Process',
      path: ROUTES.FORMS.REQUISITION.INTERVIEW_PROCESS,
      to: pathToUrl(ROUTES.FORMS.REQUISITION.INTERVIEW_PROCESS, params),
      component: InterviewProcess,
      canView: false, // !!values?.specialisation?.id,
    },
    {
      title: 'Candidates',
      path: ROUTES.FORMS.REQUISITION.CANDIDATES,
      to: pathToUrl(ROUTES.FORMS.REQUISITION.CANDIDATES, params),
      component: CommonCandidatesTable.default,
      canView:
        !!values?.field_options?.permissions?.includes(
          PermissionTypes.ViewSpecialisationCandidates,
        ) && requisitionSettings?.enable_candidate_tab,
      icon: <Icon name="Profile" />,
    },
    {
      title: 'Changelog',
      path: ROUTES.FORMS.REQUISITION.CHANGELOG,
      to: pathToUrl(ROUTES.FORMS.REQUISITION.CHANGELOG, params),
      component: Changelog,
      icon: <Icon name="Menu" />,
    },
    {
      title: 'Hires',
      path: ROUTES.FORMS.REQUISITION.HIRES,
      to: pathToUrl(ROUTES.FORMS.REQUISITION.HIRES, params),
      component: Hires,
      canView:
        !!values?.field_options.permissions?.includes(
          PermissionTypes.ViewRequisitionHires,
        ) && requisitionSettings?.enable_hiring_process,
      icon: <Icon name="People" />,
    },
  ]

  const filteredTabs = tabs.filter(tab =>
    tab.canView === undefined ? true : tab.canView,
  )

  return (
    <PageWrapper>
      <PageHeader
        title={
          <Box maxWidth={BREAKPOINTS.xl}>
            <RequisitionFormTitle
              data={{ ...values, status: initialValues.status! }}
              actions={<RequisitionActionBar onApproving={setIsApproving} />}
            />
          </Box>
        }
        backUrl={defaultBackUrl}
      >
        <Box pb="s-8" maxWidth={BREAKPOINTS.xl}>
          <TabBarNavigation tabs={filteredTabs} />
        </Box>
      </PageHeader>
      <Flex pt="s-8" flex="1 0" flexDirection="column" maxWidth={BREAKPOINTS.xl}>
        <Switch>
          {filteredTabs.map(tab => (
            <Route exact path={tab.path} key={tab.path}>
              <tab.component data={values} type="requisition" isApproving={isApproving} />
            </Route>
          ))}
        </Switch>
      </Flex>
    </PageWrapper>
  )
})

const RequisitionPage = () => {
  return (
    <Form api={requisitionsRequestsNew}>
      <Requisition />
    </Form>
  )
}

export default connect(RequisitionPage)
